<template>
  <b-modal
    id="import-vehicle-modal"
    cancel-variant="outline-secondary"
    ok-variant="success"
    :cancel-title="$t('button.close')"
    size="lg"
    :title="$t('button.import')"
    centered
    @ok="handleOk"
  >
    <validation-observer ref="importForm" #default="{ invalid }">
      <n-form-confirmation
        ref="importFormConfirm"
        key="importForm"
        ok-variant="success"
        ok-title="button.import"
        @submit="submit"
        :form="$refs.importForm"
        :disabled="invalid"
      >
        <n-input :fields="fields" v-model="data" :key="`import-${key}`">
          <template #file>
            <b-form-group :label="$t('field.excel')">
              <validation-provider
                #default="{ errors }"
                vid="excel"
                name="field.excel"
                :rules="`required`"
              >
                <b-form-file
                  name="excel"
                  :state="errors.length > 0 ? false : null"
                  v-model="data.file"
                  :browse-text="$t('button.browse')"
                  :placeholder="$t('button.chooseFileOrDropHere')"
                  :drop-placeholder="$t('button.dropHere')"
                ></b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
                <b-row class="pt-1">
                  <b-col class="text-right">
                    <b-link class="link-underline" href="/import-vehicle-template.xlsx">
                      {{ $t("button.downloadTemplate") }}
                    </b-link>
                  </b-col>
                </b-row>
              </validation-provider>
            </b-form-group>
          </template>
        </n-input>
      </n-form-confirmation>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ $t("button.import") }}</span>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormFile,
  BSpinner,
  BRow,
  BCol,
  BLink,
} from "bootstrap-vue";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import Repository from "@/repositories/RepositoryFactory";
import FileSaver from "file-saver";

const ImportVehicleRepository = Repository.get("importVehicle");

export default {
  components: {
    BModal,
    BFormGroup,
    BFormFile,
    BSpinner,
    BRow,
    BCol,
    BLink,
    NFormConfirmation,
    NInput,
  },
  data() {
    return {
      loading: false,
      key: 1,
      data: {
        referenceNo: null,
        arriveDate: null,
        file: null,
      },
    };
  },
  methods: {
    show() {
      this.data = {
        referenceNo: null,
        arriveDate: null,
        file: null,
      };
      this.key++;
      this.$bvModal.show("import-vehicle-modal");
    },
    hide() {
      this.$bvModal.hide("import-vehicle-modal");
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.importForm.validate().then((success) => {
        if (success) {
          this.$refs.importFormConfirm.confirm();
        }
      });
    },
    submit() {
      console.log("Submit");
      this.loading = true;
      let formData = new FormData();
      formData.append("file", this.data.file);
      formData.append("arriveDate", this.data.arriveDate);
      formData.append("referenceNo", this.data.referenceNo);
      ImportVehicleRepository.import(formData)
        .then((response) => {
          this.hide();
          this.$emit("success");
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.importForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = FormInput;

    return {
      fields,
    };
  },
};
</script>