export default [
  {
    key: 'vehicleId',
    label: 'field.lot',
    type: 'text',
    operator: '%',
  },
  {
    key: 'checkinAt',
    label: 'field.checkinDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
  {
    key: 'vinNumber',
    label: 'field.vinNumber',
    type: 'text',
    operator: '%',
  },
  {
    key: 'year',
    label: 'field.year',
    type: 'year-range-picker',
    cast: 'range',
    disableType: 'after',
    searchField: "year",
  },
  {
    key: 'vehicleBrandId',
    label: 'field.brand',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'vehicleBrand',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'vehicleModelId',
    label: 'field.model',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'vehicleModel',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'locationId',
    label: 'field.location',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'location',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'zoneId',
    label: 'field.zone',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'zone',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'status',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'status.1', value: '1' },
      { text: 'status.2', value: '2' },
      { text: 'status.3', value: '3' },
      { text: 'status.4', value: '4' },
      { text: 'status.5', value: '5' },
      { text: 'status.6', value: '6' },
      { text: 'status.7', value: '7' },
      { text: 'status.8', value: '8' },
      { text: 'status.9', value: '9' },
      { text: 'status.10', value: '10' },
      { text: 'status.11', value: '11' },
      { text: 'status.12', value: '12' },
      { text: 'status.13', value: '13' },
      { text: 'status.14', value: '14' },
      { text: 'status.15', value: '15' },
      { text: 'status.16', value: '16' },
    ],
  },
  {
    key: 'transferStatus',
    label: 'field.transferStatus',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'transferStatus.1', value: '1' },
      { text: 'transferStatus.2', value: '2' },
    ],
  },
  {
    key: 'auctionDate',
    label: 'field.auctionDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
  {
    key: 'checkoutAt',
    label: 'field.checkoutDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
  {
    key: 'inspectAt',
    label: 'field.inspectedAt',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
  {
    key: 'inspectBy',
    label: 'field.inspector',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'inspector',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'sellTarget',
    label: 'field.channel',
    type: 'single-selection',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'field.b2b', value: 'b2b' },
      { text: 'field.b2c', value: 'b2c' },
    ],
  },
  {
    key: 'arrivalDate',
    label: 'field.arrivalDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
  {
    key: 'bidBy',
    label: 'field.dealer',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'dealer',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'bdPic',
    label: 'field.bdPic',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'user',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
];
