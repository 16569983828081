<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="12" md="auto" class="text-right mt-1 mt-md-0">
            <b-row align-h="end">
              <b-col
                class="pr-0"
                cols="auto"
                v-if="$can('create', 'export-car-gate-pass')"
              >
                <b-button
                  :variant="!selected.length ? 'secondary' : 'primary'"
                  :disabled="!selected.length"
                  @click="downloadGatePass"
                >
                  {{ $t("button.downloadGatePass") }}
                </b-button>
              </b-col>
              <b-col
                class="pr-0"
                cols="auto"
                v-if="$can('create', 'vehicle-transfer')"
              >
                <transfer-modal :selected="selected" @success="getData" />
              </b-col>
              <b-col class="pr-0" cols="auto">
                <b-button variant="primary" @click="downloadQrCode">
                  {{ $t("button.downloadQrCode") }}
                </b-button>
              </b-col>
              <b-col class="pr-0" cols="auto">
                <export-excel-button
                  :query="query"
                  :fields="fields"
                  :search-fields="searchFields"
                  :loading="loading"
                  file-name="Vehicle"
                  :repository="VehicleRepository"
                  :selected="selected"
                  v-model="loading"
                />
              </b-col>
              <b-col class="pr-0" cols="auto">
                <b-button
                  variant="primary"
                  @click="importVehicle"
                  v-if="$can('create', resource)"
                >
                  {{ $t("button.import") }}
                </b-button>
              </b-col>
              <b-col class="pr-0" cols="auto">
                <b-button
                  variant="success"
                  :to="{ name: `create-${route}` }"
                  v-if="$can('create', resource)"
                >
                  {{ $t("button.create") }}
                </b-button>
              </b-col>
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          @destroy="destroy"
          @select="select"
          :fields="fields"
          :visible-columns="visibleColumns"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :viewable="true"
          :deletable="true"
          :resource="resource"
          :route="route"
          primary-field="vinNumber"
          :is-select="isSelect"
          :selected="selected"
        >
          <template #head(increasement)>
            <div
              class="text-nowrap cursor-pointer"
              @click="isSelect = !isSelect"
            >
              <feather-icon
                icon="CheckSquareIcon"
                v-if="!isSelect"
              ></feather-icon>
              <feather-icon icon="XIcon" v-else></feather-icon>
            </div>
          </template>
          <template #cell(auctionDate)="data">
            <span v-if="data.item.auctionDate">
              {{ data.item.auctionDate | formatDate("DD-MMMM-YYYY") }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(checkinDate)="data">
            <span v-if="data.item.checkinDate">
              {{ data.item.checkinDate | formatDate }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(checkoutDate)="data">
            <span v-if="data.item.checkoutDate">
              {{ data.item.checkoutDate | formatDate }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(inspectedAt)="data">
            <span v-if="data.item.inspectedAt">
              {{ data.item.inspectedAt | formatDate }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(updatedBy)="data">
            <span v-if="data.item.updatedBy">
              {{ data.item.updatedBy.name }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(inspectedBy)="data">
            <span v-if="data.item.inspectedBy">
              {{ data.item.inspectedBy.name }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(dealer)="data">
            <span v-if="data.item.dealer">
              {{ data.item.dealer.name }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(bdPic)="data">
            <span v-if="data.item.bdPic">
              {{ data.item.bdPic.name }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(sellTarget)="data">
            <b-badge
              :variant="data.item.sellTarget == 'b2b' ? `primary` : `success`"
              pill
              small
              class="text-uppercase"
              v-if="data.item.sellTarget"
            >
              {{ data.item.sellTarget }}
            </b-badge>
            <span v-else>-</span>
          </template>
          <template #cell(reservePrice)="data">
            <span v-if="data.item.reservePrice">
              {{ data.item.reservePrice | currency }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(buyNowPrice)="data">
            <span v-if="data.item.buyNowPrice">
              {{ data.item.buyNowPrice | currency }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(finalPrice)="data">
            <span v-if="data.item.finalPrice">
              {{ data.item.finalPrice | currency }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(revisedPrice)="data">
            <span v-if="data.item.revisedPrice">
              {{ data.item.revisedPrice | currency }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(status)="data">
            <span :class="getTextClassByStatus(data.item.status)">
              {{ $t(`status.${data.item.status}`) }}
            </span>
          </template>
          <template #cell(transferStatus)="data">
            <span
              :class="`transfer-${data.item.transferStatus}`"
              v-if="data.item.transferStatus > 0"
            >
              {{ $t(`transferStatus.${data.item.transferStatus}`) }}
            </span>
            <span v-else> - </span>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                :to="{
                  name: `view-${route}`,
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.view')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$t('button.remark')"
                variant="primary"
                pill
                size="sm"
                @click="openRemark(data.item)"
              >
                <feather-icon icon="MessageCircleIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$t('button.transfer')"
                variant="primary"
                pill
                size="sm"
                @click="openTransferLog(data.item)"
              >
                <feather-icon icon="TruckIcon" />
              </b-button>
              <b-button
                :disabled="!$can('delete', resource)"
                v-b-tooltip.hover
                :title="$t('button.delete')"
                :variant="$can('delete', resource) ? 'danger' : 'secondary'"
                pill
                size="sm"
                @click="confirmDestroy(data.item.id, data.index, data.item)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>

    <import-modal ref="importModal" @success="importSuccess" />
    <export-gate-pass-modal
      ref="exportGatePassModal"
      @export="exportGatePass"
    />
    <remark-modal ref="remarkModal" />
    <transfer-log-modal ref="transferLogModal" />
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BBadge, BLink } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NColumnVisibility from "@/components/NColumnVisibility";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import ImportModal from "./import/Index.vue";
import moment from "moment";
import { getTextClassByStatus } from "@/libs/helper";
import ExportGatePassModal from "./exportGatePassModal/ExportModal.vue";
import ExportExcelButton from "@/components/ExportExcelButton.vue";
import RemarkModal from "./remark/RemarkModal.vue";
import TransferModal from "./transfer/TransferModal.vue";
import TransferLogModal from "./transferLog/TransferLogModal.vue";

const VehicleRepository = Repository.get("vehicle");
const VehicleGatePassRepository = Repository.get("vehicleGatePass");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BLink,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NColumnVisibility,
    vSelect,
    ImportModal,
    ExportGatePassModal,
    ExportExcelButton,
    RemarkModal,
    TransferModal,
    TransferLogModal,
  },
  watch: {
    perPage() {
      this.list();
    },
    isSelect(value) {
      if (!value) {
        this.selected = [];
      }
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.columns.vehicles;
      },
      set(value) {
        this.$store.commit("columns/SET_VEHICLE_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      selected: [],
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        id: this.$route.query.id || null,
        vinNumber: this.$route.query.vinNumber || null,
        year: this.$route.query.year || [],
        status: this.$route.query.status || [],
        transferStatus: this.$route.query.transferStatus || [],
        sellTarget: this.$route.query.sellTarget || [],
        vehicleBrandId: this.$route.query.vehicleBrandId || [],
        vehicleModelId: this.$route.query.vehicleModelId || [],
        locationId: this.$route.query.locationId || [],
        zoneId: this.$route.query.zoneId || [],
        inspectBy: this.$route.query.inspectBy || [],
        checkinAt: this.$route.query.checkinAt || [],
        auctionDate: this.$route.query.auctionDate || [],
        checkoutAt: this.$route.query.checkoutAt || [],
        inspectAt: this.$route.query.inspectAt || [],
        arrivalDate: this.$route.query.arrivalDate || [],
        bidBy: this.$route.query.bidBy || [],
        bdPic: this.$route.query.bdPic || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        id: this.$route.query.id || null,
        vinNumber: this.$route.query.vinNumber || null,
        year: this.$route.query.year || [],
        status: this.$route.query.status || [],
        transferStatus: this.$route.query.transferStatus || [],
        sellTarget: this.$route.query.sellTarget || [],
        vehicleBrandId: this.$route.query.vehicleBrandId || [],
        vehicleModelId: this.$route.query.vehicleModelId || [],
        locationId: this.$route.query.locationId || [],
        zoneId: this.$route.query.zoneId || [],
        inspectBy: this.$route.query.inspectBy || [],
        checkinAt: this.$route.query.checkinAt || [],
        auctionDate: this.$route.query.auctionDate || [],
        checkoutAt: this.$route.query.checkoutAt || [],
        inspectAt: this.$route.query.inspectAt || [],
        arrivalDate: this.$route.query.arrivalDate || [],
        bidBy: this.$route.query.bidBy || [],
        bdPic: this.$route.query.bdPic || [],
      },
      isSelect: false,
      a: null,

      VehicleRepository,
      getTextClassByStatus,
    };
  },
  mounted() {
    this.a = document.createElement("a");
    document.body.appendChild(this.a);
    this.a.style = "display: none";

    this.getData();
  },
  methods: {
    openTransferLog(item) {
      this.$refs.transferLogModal.show(item);
    },
    openRemark(item) {
      this.$refs.remarkModal.show(item);
    },
    importVehicle() {
      this.$refs.importModal.show();
    },
    importSuccess() {
      this.getData();
    },
    downloadQrCode() {
      if (this.selected.length) {
        this.downloadSelectedQrCode();
      } else {
        this.downloadFilterQrCode();
      }
    },
    downloadFilterQrCode() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: this.searchFields,
      };
      VehicleRepository.exportFilterQrCode(params)
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
              type: "application/pdf",
            }),
            url = window.URL.createObjectURL(blob);

          this.a.href = url;
          this.a.download = `QR Codes - ${moment().format("DD-MMM-YYYY")}.pdf`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    downloadSelectedQrCode() {
      this.loading = true;
      VehicleRepository.exportQrCode({
        ids: this.selected,
      })
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
              type: "application/pdf",
            }),
            url = window.URL.createObjectURL(blob);

          this.a.href = url;
          this.a.download = `QR Codes - ${moment().format("DD-MMM-YYYY")}.pdf`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    downloadGatePass() {
      if (!this.selected.length) {
        return;
      }
      this.$refs.exportGatePassModal.show();
    },
    exportGatePass(item) {
      this.loading = true;
      VehicleGatePassRepository.export({
        ids: this.selected,
        ...item,
      })
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
              type: "application/pdf",
            }),
            url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `Gate Pass - ${moment().format("DD-MMM-YYYY")}.pdf`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    select(item) {
      const index = this.selected.findIndex((element) => {
        return element == item.id;
      });
      if (index == -1) {
        this.selected.push(item.id);
      } else {
        this.selected.splice(index, 1);
      }
    },
    onColumnChange(field, checked) {
      const index = this.visibleColumns.findIndex((element) => {
        return element == field.key;
      });
      if (index != -1) {
        this.visibleColumns.splice(index, 1);
      } else {
        this.visibleColumns.push(field.key);
      }

      this.key++;
    },
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    list(page = 1) {
      this.query.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: this.searchFields,
      };
      VehicleRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    calculateOrderNumber(index) {
      const from = (this.query.page - 1) * this.perPage + 1;
      return from + index;
    },
    confirmDestroy(id, index, data) {
      index = this.calculateOrderNumber(index);

      this.$bvModal
        .msgBoxConfirm(
          this.$t("alert.unableToRevertAfterDelete") +
            ` #${index}: ${data.lot}`,
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("button.delete"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.destroy(id);
          }
        });
    },
    destroy(id) {
      VehicleRepository.destroy(id)
        .then((response) => {
          this.getData();
        })
        .catch((error) => {});
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "vehicle";
    const route = "vehicle";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
