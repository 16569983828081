<template>
  <div>
    <b-button
      :variant="!selected.length ? 'secondary' : 'primary'"
      :disabled="!selected.length"
      @click="show"
    >
      {{ $t("button.transfer") }}
    </b-button>
    <b-modal
      cancel-variant="outline-secondary"
      :cancel-title="$t('button.close')"
      centered
      no-close-on-backdrop
      :title="$t('button.transfer')"
      v-model="isShow"
      @ok="handleOk"
    >
      <validation-observer ref="transferForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          ref="formConfirmation"
          key="transferForm"
          :form="$refs.transferForm"
          :disabled="invalid"
          @submit="submit"
        >
          <n-input :fields="formInput" v-model="data"> </n-input>
        </n-form-confirmation>
      </validation-observer>

      <template #modal-ok>
        <b-spinner small label="Spinning" v-if="loading"></b-spinner>
        <span v-else>{{ $t("button.transfer") }}</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, BSpinner, BFormGroup } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import Repository from "@/repositories/RepositoryFactory";

const VehicleRepository = Repository.get("vehicle");

export default {
  components: {
    BButton,
    BModal,
    BSpinner,
    BFormGroup,

    NFormConfirmation,
    NInput,
  },
  props: {
    selected: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    "data.type": function (value) {
      this.updateTitle(value);
      return;
    },
  },
  data() {
    return {
      loading: false,
      isShow: false,
      data: {
        locationId: null,
      },
    };
  },
  methods: {
    show() {
      this.data = {
        locationId: null,
      };
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.transferForm.validate().then((success) => {
        if (success) {
          this.$refs.formConfirmation.confirm();
        }
      });
    },
    submit() {
      this.loading = true;
      VehicleRepository.transfer({
        vehicleIds: this.selected,
        locationId: this.data.locationId,
      })
        .then((response) => {
          this.loading = false;
          this.hide();
          this.$emit("success");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const formInput = [...FormInput];

    return {
      formInput,
    };
  },
};
</script>