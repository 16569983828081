<template>
  <div>
    <b-button variant="primary" @click="showExportModal()" :disabled="loading">
      {{ $t("button.export") }}
    </b-button>
    <b-modal
      ref="modal"
      size="lg"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      :cancel-title="$t('button.close')"
      centered
      no-close-on-backdrop
      :title="$t('button.exportExcel')"
      v-model="exportModelVisible"
      @ok="exportExcel"
    >
      <b-row>
        <b-col
          md="6"
          lg="4"
          xl="3"
          v-for="(field, index) in columns"
          :key="`column-${index}`"
        >
          <b-form-checkbox v-model="field.isShow" class="text-left pb-1">
            {{ $t(field.label) }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6">
          <b-button variant="danger" block @click="resetColumn">
            {{ $t("button.reset") }}
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button variant="primary" block @click="checkedAllColumn">
            {{ $t("button.selectAll") }}
          </b-button>
        </b-col>
      </b-row>

      <template #modal-ok>
        <b-spinner small label="Spinning" v-if="loading"></b-spinner>
        <span v-else>{{ $t("button.export") }}</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormCheckbox,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
import moment from "moment";
import FileSaver from "file-saver";
import { mapExportField } from "@/libs/helper";

export default {
  components: {
    BButton,
    BModal,
    BFormCheckbox,
    BRow,
    BCol,
    BSpinner,
  },
  props: {
    query: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    searchFields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    extraQueries: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selected: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      default: "File",
    },
    repository: {},
  },
  data() {
    return {
      exportModelVisible: false,
      columns: [],
    };
  },
  methods: {
    checkedAllColumn() {
      let columns = [];
      this.fields.forEach((element) => {
        if (!element.skipExport) {
          columns.push({
            ...element,
            isShow: true,
          });
        }
      });
      this.columns = [...columns];
      this.key++;
    },
    resetColumn() {
      let columns = [];
      this.fields.forEach((element) => {
        if (!element.skipExport) {
          columns.push({
            ...element,
            isShow: element.hideToggle,
          });
        }
      });
      this.columns = [...columns];
    },
    showLoading() {
      this.$emit("input", true);
    },
    hideLoading() {
      this.$emit("input", false);
    },
    showExportModal() {
      this.resetColumn();
      this.exportModelVisible = true;
    },
    exportExcel() {
      if (this.repository) {
        this.showLoading();
        let visibleColumns = [];
        this.columns.forEach((element) => {
          if (element.isShow) {
            visibleColumns.push(element.key);
          }
        });
        let query = {
          ...this.query,
          searchFields: this.searchFields,
          fields: mapExportField(this.fields, visibleColumns),
        };

        if (this.selected.length > 0) {
          query = {
            query: [
              {
                searchby: "id",
                searchoperator: "in",
                search: this.selected,
              },
            ],
            fields: mapExportField(this.fields, visibleColumns),
          };
        }

        this.repository
          .exportExcel(query)
          .then((response) => {
            const fileName = `${this.fileName} - ${moment(
              this.query.dateFrom
            ).format("LL")} - ${moment(this.query.dateTo).format("LL")}.xlsx`;
            FileSaver.saveAs(response.data, fileName);
          })
          .catch(() => {
            this.hideLoading();
          })
          .then(() => {
            this.hideLoading();
          });
      }
    },
  },
};
</script>