export default [
  {
    key: 'runnerName',
    label: 'field.runnerName',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'nationalId',
    label: 'field.nationalId',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'contactNumber',
    label: 'field.contactNumber',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
]
