<template>
  <b-modal
    id="export-modal"
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    no-close-on-backdrop
    :title="$t('field.gatePass')"
    @ok="handleOk"
  >
    <validation-observer ref="exportForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="exportForm"
        :form="$refs.exportForm"
        :disabled="invalid"
        @submit="submit"
      >
        <n-input :fields="formInput" v-model="data"> </n-input>
      </n-form-confirmation>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ $t("button.export") }}</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BSpinner, BFormGroup } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";

export default {
  components: {
    BModal,
    BSpinner,
    BFormGroup,

    NFormConfirmation,
    NInput,
  },
  watch: {
    "data.type": function (value) {
      this.updateTitle(value);
      return;
    },
  },
  data() {
    return {
      loading: false,
      data: {
        runnerName: null,
        nationalId: null,
        contactNumber: null,
      },
    };
  },
  methods: {
    show() {
      this.data = {
        runnerName: null,
        nationalId: null,
        contactNumber: null,
      };

      this.$bvModal.show("export-modal");
    },
    hide() {
      this.$bvModal.hide("export-modal");
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.exportForm.validate().then((success) => {
        if (success) {
          this.$refs.formConfirmation.confirm();
        }
      });
    },
    submit() {
      this.$emit("export", this.data);
      this.hide();
    },
  },
  setup() {
    const formInput = [...FormInput];

    return {
      formInput,
    };
  },
};
</script>