<template>
  <b-modal
    id="comment-modal"
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    no-close-on-backdrop
    hide-footer
    size="lg"
  >
    <validation-observer ref="commentForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="commentForm"
        :form="$refs.commentForm"
        :disabled="invalid"
        @submit="submit"
      >
        <n-input :fields="formInput" v-model="data"> </n-input>
        <b-row>
          <b-col cols="12" class="text-right">
            <n-button-loading
              type="submit"
              variant="primary"
              :loading="loading"
            >
              {{ $t("button.submit") }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
    <hr />
    <n-table
      ref="table"
      :fields="fields"
      :items="items"
      :busy="loading"
      :current-page="query.page"
      :per-page="query.limit"
      :total="total"
      :resource="resource"
      :route="route"
    >
      <template #cell(createdBy)="data">
        <span v-if="data.item.createdBy">
          {{ data.item.createdBy.name }}
        </span>
        <span v-else>-</span>
      </template>
      <template #cell(status)="data">
        <span
          :class="getTextClassByStatus(data.item.status)"
          v-if="data.item.status"
        >
          {{ $t(`status.${data.item.status}`) }}
        </span>
        <span v-else>-</span>
      </template>
    </n-table>
    <n-pagination
      ref="pagination"
      :total="total"
      :per-page="query.limit"
      :page="query.page"
      @change="list"
    ></n-pagination>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import NPagination from "@/components/NPagination";
import { getTextClassByStatus } from "@/libs/helper";

const VehicleCommentRepository = Repository.get("vehicleComment");

export default {
  components: {
    BModal,
    BRow,
    BCol,

    NFormConfirmation,
    NInput,
    NButtonLoading,
    NTable,
    NPagination,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  watch: {
    "data.type": function (value) {
      this.updateTitle(value);
      return;
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      query: {
        page: 1,
        limit: 10,
      },
      data: {
        vehicleId: 1,
        comment: null,
      },
      id: null,

      getTextClassByStatus,
    };
  },
  methods: {
    show(item) {
      this.id = item.id;
      this.data = {
        vehicleId: item.id,
        comment: null,
      };
      this.items = [];
      this.total = 0;
      this.query.page = 1;
      this.getData();

      this.$bvModal.show("comment-modal");
    },
    hide() {
      this.$bvModal.hide("comment-modal");
    },
    list(page = 1) {
      this.query.page = page;
      this.getData();
    },
    getData() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const params = {
        ...this.query,
        searchFields: [],
        query: [
          {
            searchby: "vehicle_id",
            searchoperator: "=",
            search: this.id,
          },
        ],
      };
      VehicleCommentRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.commentForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          VehicleCommentRepository.create(this.data)
            .then((response) => {
              this.data.comment = null;
              this.$nextTick(() => {
                this.$refs.commentForm.reset();
              });
              this.getData();
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.commentForm.setErrors(error.response?.data?.errors);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  setup() {
    const fields = [...TableFields];
    const formInput = [...FormInput];
    const resource = "vehicle";
    const route = "vehicle";

    return {
      fields,
      formInput,
      resource,
      route,
    };
  },
};
</script>